header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.btn-wrapper button {
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  background-color:#68c799;
  min-width: 130px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.btn-wrapper button:hover {
  background: #41ba7c;
  color: white;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f1f7fe;
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 16px 0;
  border: none;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav-top {
  background-color: #f1f7fe;
  padding-block: 5.5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.089);
}
.nav-bottom {
  background-color: #454545;
}

.nav-menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-menu ul li a {
  padding: 10px 15px !important;
  border-radius: 5px;
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
}
.nav-menu ul li a span svg {
  font-size: 16px;
  transform: rotate(60deg);
}
.nav-menu ul li a:hover span svg {
  transform: rotate(-45deg);
}
.nav-menu ul li a.active span svg {
  transform: rotate(-45deg);
}
.nav-menu ul li a.active {
  color: #41ba7c;
}
.nav-menu ul li a:hover {
  color: #41ba7c;
}

.logo-wrapper {
  padding: 15px 0px;
}
.big-logo {
  display: block;
  width: 250px;
  transform: scale(1);
  transition: all 1s ease;
}
.logo-wrapper img {
  width: 100%;
}

.small-logo {
  display: none;
  width: 100px;
  transform: scale(1);
  transition: all 1s ease;
}

.header.scrolled .big-logo {
  display: none;
  transition: all 1s ease;
  transform: scale(1);
}

.header.scrolled .small-logo {
  display: block;
  transition: all 1s ease;
}
.position-lg-relative {
  position: relative;
}
.bar {
  display: none;
}

@media (max-width: 992px) {
  .bar {
    display: block;
  }
  .position-lg-relative {
    position: unset;
  }
  .nav-menu {
    position: absolute;
    top: 100%;
    left: -400px;
    background: #ebebeb;
    padding: 20px 10px;
    transition: 0.5s ease-in-out;
  }
  .nav-menu.open {
    left: 0;
  }

  .logo-wrapper {
    height: 100%;
    background: transparent;
    box-shadow: none;
  }
  .nav-bottom {
    display: none;
  }
  .nav-menu ul {
    display: block;
  }
  .nav-menu ul li {
    margin: 5px 0;
    min-width: 200px;
  }
  .nav-menu ul li a {
    padding: 10px 20px;
    display: block;
  }
}

footer {
  background: linear-gradient(45deg, #000000, #081e13);
  padding:0 20px 20px 20px;
  color: white;
}
.footer-logo {
  max-width: 190px;
  margin-bottom: 30px;
}

.socal i {
  color: #ffffff;
  font-size: 25px;
  margin-left: 10px;
}

.socal i:hover {
  color: #ef644c;
}

.footer-contaner h3 {
  margin-bottom: 20px;
  font-size: 20px;

  color: rgb(255, 255, 255);
  font-weight: 600;
}

.app-link-wrapper img {
  max-width: 150px;
}

.footer-contaner li a {
  color: rgb(255, 255, 255);
  word-break: break-word;
  font-weight: 300;
  font-size: 15px;
}

.hoverable li a {
  position: relative;
}

.hoverable li {
  margin: 10px 0;
}

.hoverable li a::after {
  content: "";
  display: none;
  height: 0px;
  border-bottom: solid 1px white;
}

.hoverable li a i {
  display: none;
}

.hoverable li a:hover::after {
  display: none;
}

.hoverable li a:hover {
  display: inline;
}

@media (max-width: 576px) {
  .big-logo {
    display: block;
    width: 150px;
   
}
  .copyright p {
    font-size: 14px;
  }
}
