

h1 {
    margin-bottom: 20px;
    font-size: 2.5rem;
}

.banner-container {
    perspective: 1000px;
}

.flip-card {
  width: 200px;
  height: 280px;
    position: relative;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 1s; 
    transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.flip-card-front {
    transform: rotateY(0deg);
}

.flip-card-back {
    transform: rotateY(180deg);
}

.flip-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.flipped .flip-card-inner {
    transform: rotateY(180deg);
}


  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 406px) {
    .flip-card {
      width: 180px;
        position: relative;
    }
}
@media (max-width: 306px) {
  .flip-card {
    width: 140px;
      position: relative;
  }
}